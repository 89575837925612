<template>
  <div>
    <div class="sr_base" v-if="$route.params.id">
      <div class="back">
        <el-button icon="el-icon-back" @click="back"  >Back to Rental Listings</el-button >
      </div>
      <div class="info-mobile" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
        <div class="property-address">{{ data?.address }}</div>
        <el-button v-if="$route.params.id" @click="$refs.shareListingsDialog.show = true" style="border: none; "><img src="../../assets/icon/icon-share.svg"/></el-button>
        <div  v-if="data?.homeowner" class="homeowner" style="margin-bottom: 0;" @click="openHomeownerDetail"  >
          Owner :&nbsp;<span style="text-decoration: underline;">{{ data?.homeowner.name }}</span>
        </div>
      </div>
      <div class="row infor-row" v-loading="loading && !data">
        <div class="thumbnail-container">
          <img class="thumbnail" v-if="data?.images && data.images.length" :src="data.images[0].small_url" alt="">
          <div class="area" v-if="data?.area">{{data.area?.name}}</div>
        </div>
        <div class="detail-container">
          <div style="display: flex; flex-direction: row; align-items: center;">
            <div class="property-address">{{ data?.address }}</div>
            <el-button v-if="$route.params.id" @click="$refs.shareListingsDialog.show = true" style="border: none; margin-right: 54px;"><img src="../../assets/icon/icon-share.svg"/></el-button>
            <div  v-if="data?.homeowner" class="homeowner" @click="openHomeownerDetail"  >
              Owner :&nbsp;<span style="text-decoration: underline;">{{ data?.homeowner.name }}</span>
            </div>
          </div>
          <div style="width: 100%; height: 1px; background:#DEE1EB;"></div>
          <div style="width: 100%; display: flex;">
            <div style="width: 25%; padding: 10px;">
              <div style="display: flex; flex-direction: row; align-items: center; gap: 14.47px; margin-top: 15px;">
                <img src="../../assets/icon/icon-calendar.svg" alt="">
                <div class="info-title-price">Up to {{ data?.max_price | currencyPrice('$ ') }}/Wk</div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; gap: 25.75px; margin-top: 25px;">
                <div style="display: flex; gap: 20.75px; ">
                  <img src="../../assets/icon/icon-bedroom.svg" alt="">
                  <div class="info-title">Bedrooms: </div>
                </div>
                <div class="info-title">
                  {{ data?.bedroom_number }}
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items:  center; justify-content: flex-start; gap: 20.75px; margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-bathroom.svg" alt="">
                  <div class="info-title">Bathrooms: </div>
                </div>
                <div class="info-title">
                  {{data?.bathroom_number}} full {{data?.half_bathroom_number!=0?", "+data?.half_bathroom_number+" "+'half':null}}
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; gap: 35.75px; margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-capacity.svg" alt="">
                  <div class="info-title">Capacity: </div>
                </div>
                <div class="info-title">
                  {{ data?.capacity }}
                </div>
              </div>
            </div>
            <div style="width: 40%; padding: 10px 60px 10px 10px;">
              <div class="table_header">
                <div>Bedroom</div>
                <div>Floor</div>
                <div>Type</div>
                <div>Qty</div>
              </div>
              <div class="table_item" v-for="(item, index) in data?.bedrooms" :key="index">
                  <div>{{index + 1}}</div>
                  <div style="margin-left: 40px;">{{item.floor_level?.name}}</div>
                  <div>{{item.beds[0]?.type?.name}}</div>
                  <div>{{item.beds[0]?.number}}</div>
              </div>
            </div>
            <div style="width: 30%;">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-right: 25px;  margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-air.svg" alt="">
                  <div class="info-title">Air Conditioning: </div>
                </div>
                <div class="info-title">
                  {{ data?.ac_types[0]?.name ? data.ac_types[0]?.name : "None" }}
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-right: 25px;  margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-pets.svg" alt="">
                  <div class="info-title">Pets: </div>
                </div>
                <div class="info-title">
                  {{ data?.requirement.pet_allow == "true" ?  "Yes" : data?.requirement.pet_allow == "false" ? "No" : "Ask owner" }}
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-right: 25px;  margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-pool.svg" alt="">
                  <div class="info-title">Pool: </div>
                </div>
                <div class="info-title">
                  {{ data?.pool_type?.name ? data.pool_type?.name : "No" }}
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-right: 25px;  margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-walk-to-beach.svg" alt="">
                  <div class="info-title">Walk to the beach? </div>
                </div>
                <div class="info-title">
                   {{ data?.walk_to_beach ? "Yes" : "No" }}
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-right: 25px;  margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-water-front.svg" alt="">
                  <div class="info-title">Waterfront: </div>
                </div>
                <div class="info-title">
                  {{ data?.waterfront ? "Yes" : "No" }}
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-right: 25px;  margin-top: 7px;">
                <div style="display: flex; gap: 20.75px;">
                  <img src="../../assets/icon/icon-water-view.svg" alt="">
                  <div class="info-title">Waterview: </div>
                </div>
                <div class="info-title">
                  {{ data?.water_view ? "Yes" : "No" }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row property-box ">
      <div class="siade_right pc">
        <div class="sr_bar">
            <div class="exterior" v-if="$route.params.id">
              <router-link class="sr_fo row"  exact :to=" $route.params.id ? '/property/' + $route.params.id +'/location' : '/property' " >
                <span>Location </span>
                <i v-if="$store.state.propertyInfor === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <div class="exterior" v-if="$route.params.id">
              <router-link class="sr_fo row"  exact :to=" $route.params.id ? '/property/' + $route.params.id +'/descriptions': '/property' " >
                <span>Descriptions </span>
                <i v-if="$store.state.propertyInfor === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <div class="exterior" v-if="$route.params.id">
              <router-link class="sr_fo row"  exact :to=" $route.params.id ? '/property/' + $route.params.id+'/general' : '/property' " >
                <span>General Info & House Rules </span>
                <i v-if="$store.state.propertyInfor === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <div class="exterior" v-if="$route.params.id">
              <router-link class="sr_fo row"  exact :to=" $route.params.id ? '/property/' + $route.params.id +'/amenities': '/property' " >
                <span>Amenities </span>
                <i v-if="$store.state.propertyInfor === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <div class="exterior" v-if="$route.params.id">
              <router-link class="sr_fo row"  exact :to=" $route.params.id ? '/property/' + $route.params.id +'/bedroomsandbathrooms': '/property' " >
                <span>Bedrooms and Bathrooms </span>
                <i v-if="$store.state.propertyInfor === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <div class="exterior" v-if="!$route.params.id">
              <router-link class="sr_fo row"  exact :to=" $route.params.id ? '/property/' + $route.params.id : '/property' " >
                <span>Property Information </span>
                <i v-if="$store.state.propertyInfor === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <div class="exterior">
              <router-link class="sr_fo row"  exact :to="'/property/' + $route.params.id + '/calendar'">
                <span> Calendar</span><i v-if="$store.state.propertyCalendar === true" class="el-icon-success" ></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <div class="exterior">
              <router-link class="sr_fo row"   exact :to="'/property/' + $route.params.id + '/photo'">
                <span> Photos</span><i  v-if="$store.state.propertyPhoto === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
            <!-- <div class="exterior">
              <router-link class="sr_fo row" exact :to="'/property/' + $route.params.id + '/requirement'">
                <span>House Rules / Requirements</span><i v-if="$store.state.propertyReuirement === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div> -->
            <div class="exterior">
              <router-link class="sr_fo row" exact :to="'/property/' + $route.params.id + '/service'" >
                <span>Services Providers</span ><i v-if="$store.state.propertySevice === true" class="el-icon-success"></i><img class="img" v-else :src="loading" alt="" />
              </router-link>
            </div>
        </div>
      </div>
       <Property-Move-Navigation class="move"></Property-Move-Navigation>
      <div class="content-router"><router-view></router-view></div>
    </div>
    <!-- share listing的弹出框 -->
    <Share-Listings-Dialog ref="shareListingsDialog" :listings="checkData" ></Share-Listings-Dialog>
  </div>
</template>
<script>
import { apiGetListing } from "../../API/api";
import ShareListingsDialog from "./Component/ShareListingsDialog";
import PropertyMoveNavigation from "./PropertyMoveNavigation"
export default {
  data() {
    return {
      show: false,
      loading: require("../../assets/icon/ico-progress.svg"),
      loaded: require("../../assets/icon/ico-unselected.svg"),
      checkData:[],
      clicknumber: 1,
      data: null,
      loading: false,
    };
  },
  components:{ ShareListingsDialog, PropertyMoveNavigation },
  beforeRouteUpdate(to, from, next) {
    if ( (to.name === "property-calendar" || to.name === "property-photo" || to.name === "property-requirement" ||  to.name === "property-service") && (!to.params.id || to.params.id === "undefined")
    ) {
      this.$message.error("Please upload the house information first");
      next("/property");
    } else {
      next();
    }
  },
  methods: {
    getListingsData() {
      this.loading = true
      apiGetListing(this.$route.params.id, { show_price: true })
        .then((res) => {
          this.data = res
          this.$store.commit("setPropertyCard", JSON.parse(JSON.stringify(res)));
        })
        .finally(() => {
          this.loading = false
        })
    },
    openHomeownerDetail() {
      let routeData =  this.$router.resolve({ name: "show-contact", params: { id: this.$store.state.listing.homeowner.contact_id } });
      window.open(routeData.href, '_blank');
    },
    flushCom() {
      this.show = false;
    },
    back() {
      this.$router.push({name: "properties",})
      // this.$router.go(-this.clicknumber)
    },
  },
  watch: {
  $route: {
    handler: function(val, oldVal){
      this.clicknumber +=1;
    },
    // 深度观察监听
    deep: true
  }
},
mounted(){
  this.data = this.$store.state.propertyCard
  this.getListingsData()
},
created(){
  let obj = { listing_id:this.$route.params.id}
  this.checkData.push(obj);
}
};
</script>
<style lang="scss" scoped>
@import "./PropertyNavigation.scss";
@import "../../css/button.scss";
.sr_base{
  margin-bottom: 50px;
}
.thumbnail-container{
  position: relative;
  .thumbnail {
    width: 298px;
    height: 220px;
    border-radius: 4px;
  }
  .area {
    position: absolute;
    top: 13.75px;
    left: 10.64px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #074059;
    background-color: white;
    padding: 8px 10px;
    border-radius: 3px;
  }

}
.info-mobile{
  display: none !important;
}
.detail-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 1000px;
  .table_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 20px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    border-bottom: 1px solid #DEE1EB;
  }
  .table_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: #6D7380;
    border-bottom: 1px solid #DEE1EB;
  }
}
.row{
  gap: 26px !important;
  align-items: flex-start;
}
.info-title{
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #6D7380;
}
.info-title-price{
  font-size: 14px;
  font-weight: 600;
  line-height: 18.6px;
  color: #1C4A5E;
}
@media (max-width: 992px) {
  .infor-row {
    display: none;
  }
  .info-mobile{
    display: flex !important;
  }
}

</style>

<template>
  <el-dialog title="Select contacts to share listings" :visible.sync="show" width="700px" >
    <div class="rental_listing">
      <div class="search_rent">
        <el-input v-model="search" placeholder="Search by name, email" @keyup.enter.native="searchFilter" />
      </div>
      <div class="table" v-if="data.length != 0">
        <el-table :data="data" style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="NAME" width="260">
            <template slot-scope="scope" >{{ scope.row.first_name }} {{ scope.row.last_name }}</template>
          </el-table-column>
          <el-table-column prop="email1" label="EMAIL"></el-table-column>
        </el-table>
        <Page :total="totalPage" :pageSize="10" @number="pagesChanged"></Page>
      </div>
      <div class="send-email-to" style="margin-bottom: 10px">
        <div style="margin: 0 0 10px 0">
          <el-checkbox v-model="checked">Send to someone else</el-checkbox>
        </div>
        <el-input v-if="checked" class="other-email" v-model="other_email" style="width: 60%" placeholder="Enter email address"/>
        <div>
          <span class="title-url">Click the following to copy a shareable link :</span>
          <p readonly class="inviteCode" v-text="short_url" @click="CopyUrl"></p>
        </div>
        
      </div>
      <p style="text-align: left">Enter a message to the recipient：</p>
      <el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="Please input" resize="none" v-model="comment">
      </el-input>
    </div>
    <span slot="footer" class="dialog-footer share_btn">
      <el-button @click="share" class="primary-button">Share</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { apiGetContacts, apiShareListings } from "../../../API/api";
import { Base64 } from "js-base64";
import Page from "../../common/Paging";
import qs from "qs";
import { apiCreateContactComment,} from "../../../API/api";
// function zip(str){
// 	let binaryString = pako.gzip(encodeURIComponent(str), { to: 'string' });
// 	return btoa(binaryString);
// }
export default {
  name: "ShareListingsDialog",
  components: { Page },
  data() {
    return {
      checked: false,
      loading: false,
      show: false,
      search: "",
      oldsearch: "",
      // firstName: "",
      // lastName: "",
      // email: "",
      // oldFirstName: "",
      // oldLastName: "",
      // oldEmail: "",
      sendTo: "all", // 发送给谁
      other_email: "",
      comment: "",
      totalPage: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 10, // 每页显示数量
      checkData: [], // 勾选的联系人
      data: [],
      // short_url:"rrrr"
    };
  },
  props: ["listings", "searchInfor"],
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      // 查询参数
      return {
        type: ["tenant", "buyer", "seller"],
        limit: this.limit,
        offset: this.offset,
        search_name_or_email: this.oldsearch,
        // first_name: this.oldFirstName,
        // last_name: this.oldLastName,
        // email1: this.oldEmail,
      };
    },
    short_url() {
      let url = "";
      let arr = "";
      this.listings.forEach((item) => {
        // arr.push(item.listing_id);
        arr+=item.listing_id+","
      });
      let obj = { listings: arr, arrival_date: this.searchInfor && this.searchInfor.start_date ? this.searchInfor.start_date : null, departure_date: this.searchInfor && this.searchInfor.end_date ? this.searchInfor.end_date : null,
      };
      obj = qs.stringify(obj);
      
      // let data= zip(obj)
      // data = qs.stringify(data);
      if (process.env.VUE_APP_MODE === "development") {
        url = "https://dev.booknantucket.com/share-listings?url=" + Base64.encode(obj);
      } else {
        url = "https://www.congdonandcoleman.com/share-listings?url=" +  Base64.encode(obj);
      }
      return url;
    },
  },
  methods: {
    //  zip(str){
    //     var binaryString = pako.gzip(str, { to: 'string' })
    //     return btoa(binaryString)
    //   },
    CopyUrl(data) {
      var oInput = document.createElement("input");
      oInput.value = this.short_url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message.success("Copy successfully");
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getContacts();
    },
    createContactNote(){
      this.checkData.forEach((item,index) => {
        apiCreateContactComment(item.contact_id ,{content:"Link to share listings: " + this.short_url}).then(res=>{
        })
      });
    },
    getContacts() {
      this.loading = true;
      apiGetContacts(this.query).then((res) => {
          this.data = res.results;
          this.totalPage = res.count;
        }).finally(() => {
          this.loading = false;
        });
    },
    searchFilter() {
      this.currentPage = 1;
      this.oldsearch = this.search;
      //  this.oldFirstName = this.firstName;
      // this.oldLastName = this.lastName;
      // this.oldEmail = this.email;
      this.getContacts();
    },
    handleSelectionChange(val) {
      this.checkData = val;
    },
    // 发送邮件给联系人
    share() {
      // 判断是否选择了房屋
      if (this.listings.length === 0) {
        this.$message.error("Please select at least one property");
        return;
      }
      // 判断是否有收件人
      let tos = [];
      this.checkData.forEach((item) => {
        tos.push(item.contact_id);
      });
      if (tos.length === 0 && !this.other_email) {
        this.$message.error("Please select at least one contact");
        return;
      }
     
      apiShareListings({
        listings: this.listings.map((listing) => listing.listing_id),
        recipients: tos,
        other_email: this.other_email ? this.other_email : "",
        arrival_date: this.searchInfor && this.searchInfor.start_date ? this.searchInfor.start_date : null,
        departure_date: this.searchInfor && this.searchInfor.end_date ? this.searchInfor.end_date : null,
        agent_comments: this.comment,
      }).then((res) => {
          this.$message.success("Successfully shared!");
           this.createContactNote();
          this.show = false;
          this.comment = this.other_email = ""
        }).catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../css/dialog.scss";
@import "../../../css/table.scss";
@import "../../../css/button.scss";
.rental_listing {
  /deep/ .el-table {
    td {
      padding: 10px 0;
    }
  }
  /deep/ .el-textarea__inner {
    font-family: "Roboto-Regular", sans-serif;
  }
  .search_rent {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 10px 0 20px 0;
  }
}
.send-email-to {
  padding: 0;
  margin: 0;
  text-align: left;
    .title-url{
      width: 350px;
      display: inline-block;
      font-family: "Roboto-Medium", sans-serif;
      margin-bottom: 5px;
    }
  .inviteCode {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display:-webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient:vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp:2; //显示的行
    line-height: 20px;
    cursor: pointer;
  }
  /deep/ .el-radio__input {
    width: 8px;
    // height: 8px;
    background-color: #f4f9fc;
    .el-radio__inner {
      border-radius: 2px;
      background-color: #f4f9fc;
    }
  }
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border: none;
    background: none;
    background-image: url("../../../assets/icon/ico-checkbox.svg");
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #606266;
  }
  /deep/ .el-radio__inner::after {
    display: none;
  }
  .other-email {
    width: 180px;
  }
}
.rent_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
}
.rent_footer i:hover {
  cursor: pointer;
}
</style>
